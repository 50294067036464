import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from '../lib/content/locales/en.json';
import fr from '../lib/content/locales/fr.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    fr: {
      translation: fr,
    },
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
    prefix: '{{',
    suffix: '}}',
  },
});

export { i18n };
