import { i18n } from 'lib/i18n';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { I18nextProvider } from 'react-i18next';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import 'styles/globals.css';

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const { locale, defaultLocale } = router;
  const [queryClient] = useState(() => new QueryClient());

  useEffect(() => {
    i18n.changeLanguage(locale || defaultLocale || 'en');
  }, [locale, defaultLocale]);

  return (
    <>
      <Head>
        <title>Apprentx</title>
      </Head>

      {process.env.NEXT_PUBLIC_COOKIEYES_URL && (
        <Script
          id="cookieyes"
          type="text/javascript"
          strategy="beforeInteractive"
          src={process.env.NEXT_PUBLIC_COOKIEYES_URL}
        />
      )}
      {process.env.NEXT_PUBLIC_GTM_ID && (
        <Script
          id="googleTagManager"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
              `,
          }}
        />
      )}
      {process.env.NEXT_PUBLIC_HUBSPOT_URL && (
        <Script
          id="hs-script-loader"
          async
          defer
          src={process.env.NEXT_PUBLIC_HUBSPOT_URL}
        />
      )}
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <I18nextProvider i18n={i18n}>
            <GoogleReCaptchaProvider
              reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
            >
              <Component {...pageProps} />
            </GoogleReCaptchaProvider>
          </I18nextProvider>
        </Hydrate>
      </QueryClientProvider>
    </>
  );
}
